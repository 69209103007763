import React from "react";
import { FiFacebook, FiInstagram } from "react-icons/fi";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footInfoContainer">
                <div className="footMenu">
                    Ажлын цаг
                    <div className="footInfo">
                        Даваагаас Баасан гараг хүртэл | 09:00–17:00 <br />
                        Бямба/Ням гараг | Хаалттай
                    </div>
                </div>

                <div className="footMenu">
                    Холбоо барих
                    <div className="footInfo">
                        T :  (+976) 7000-5354
                        <br />
                        M:  (+976) 8069-5353
                        <br />
                        info@allergymed.mn
                        <br />
                        <br />
                        Хатанбаатар Магсаржавын Гудамж,
                        <br /> Улаанбаатар
                    </div>
                </div>
            </div>
            <div className="footIconContainer">
                <button className="footIcon">
                    <a
                        href="https://www.facebook.com/enterolmongolia"
                        target="_blank"
                        rel="noreferrer"
                        className="link-in-button center"
                    >
                        <FiFacebook className="svgIcon" />
                    </a>
                </button>
                <button className="footIcon">
                    <a
                        href="https://www.facebook.com/allergymed"
                        target="_blank"
                        rel="noreferrer"
                        className="link-in-button center"
                    >
                        <FiInstagram className="svgIcon" />
                    </a>
                </button>
            </div>
        </div>
    )
}