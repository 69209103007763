import React from "react";
import {
    BrowserRouter
} from "react-router-dom";
import "../index.css";

import { Footer } from "./footer";
import { Header } from "./header";

export const Layout = ({ children }) => {
    console.log(children.props.children)
    return (
        <BrowserRouter>
            <Header />
            <div style={{ minHeight: '700px', width:'100vw' }}>
                {children}
            </div>  
            <Footer />
        </BrowserRouter>
    );
}
