import React from "react";
import { useLocation } from "react-router-dom";


export const Post = () => {

    const { state: { post } } = useLocation();
 
    return (
        <div>
            <div className="post-container">
                <div dangerouslySetInnerHTML={{ __html: post.docsDesc }} />
            </div>
        </div>
    )
}