import { height } from "@mui/system";
import React from "react";
import { Cop3 } from "../icons/cop3";

export const AboutUs = () => {
    return (
        <div>
            <div
                className="infoDiv infoDivAboutPage"
            >
                <div
                    className="wording marginB"
                    style={{ fontFamily: "lato", color: "grey", marginRight: "5%" }}
                >
                    <div className="openWording2 aboutWording">
                        {/* <h3 className="fontColor" style={{ fontFamily: "Martel sterfi" }}>
                            Эрхэм зорилт
                        </h3> */}
                        <h1 className="fontColor2" style={{ fontFamily: "Martel sterfi" }}>
                            Бидний тухай
                        </h1>
                        <br />
                        <p className="fontColor3">
                            “ӨЛЗИЙ ДЭН” ХХК нь 1998 оноос эхлэн Монгол улсын эрүүл мэндийн салбарт тогтвортой үйл ажиллагаа явуулж байна. Бид энэ хугацаанд Польш улсын Polfa Tarchomin S.A, Франц улсын Biocodex S.A, Солонгос улсын Rmedica Co.,Ltd, CJ Medical Co.,Ltd, Казакстан улсын Burly LLP зэрэг эмийн үйлдвэрүүдтэй хамтран ажиллаж байна.

                        </p>
                        <p className="fontColor3">
                            Өнгөрсөн хугацаанд өөрийн стратеги, хэтийн төлөвлөлтийн үндсэн дээр амжилттай ажиллаж, олон улсад танигдсан, эмчилгээний өндөр үр дүнтэй эм, эмнэлгийн хэрэгслүүдийг харилцагч, үйлчлүүлэгчиддээ хүргэж байна.
                        </p>
                        <p className="fontColor3">
                            Дотоодын болон өндөр хөгжилтэй орнуудад мэргэжлийн боловсрол эзэмшсэн эм зүйч нараас бүрдсэн чадварлаг хамт олныг бүрдүүлээд байна.
                        </p>
                    </div>
                </div>
            </div>
            <div className="center column" style={{ padding: '20px' }}>
                <h2>Бидний эрхэм зорилго</h2>
                <br />
                <p>
                    Үйлчлүүлэгчдэд өндөр идэвхитэй, чанарын баталгаатай эм, эмнэлгийн хэрэгслээр чадварлаг хүний нөөцөд
                    <br />
                    тулгуурлан хүртээмжтэй эм зүйн тусламж үйлчилгээ үзүүлэх нь бидний эрхэм зорилго юм.
                </p>
            </div>
            <div className="feature-box-container">
                <div className="feature-box">
                    <div style={{ height: '150px' }} className='center'>
                        <img style={{ height: '150px' }} src={require('../icons/feature1.png')} />
                    </div>
                    <h4>Эмийн чанар</h4>
                    <p>Европын холбооны улсуудын өндөр чанартай эм, эмнэлгийн хэрэгслүүд</p>
                </div>
                <div className="feature-box">
                    <div style={{ height: '150px' }} className='center'>
                        <img style={{ height: '150px' }} src={require('../icons/feature2.png')} />
                    </div>
                    <h4>Хүний нөөц</h4>
                    <p>Дотоодын болон гадаадын шилдэг их дээд сургуулиудад бэлтгэгдсэн баг хамт олон</p>
                </div>
                <div className="feature-box">
                    <div style={{ height: '150px' }} className='center'>
                        <img style={{ height: '150px' }} src={require('../icons/feature3.png')} />
                    </div>
                    <h4>Түгээлтийн суваг</h4>
                    <p>Үндэсний сүлжээ болон томоохон эмийн сангууд</p>
                </div>
            </div>
            <div className="center column" style={{ marginBottom: '40px', marginTop: '40px' }}>
                <h2 style={{ marginBottom: '40px' }}>
                    ХАМТРАГЧ БАЙГУУЛЛАГУУД
                </h2>
                <div className="allience-container">
                    <img style={{ width: '300px' }} src={require('../icons/cop1.png')} />
                    <img style={{ width: '300px' }} src={require('../icons/cop2.png')} />
                    <img style={{ width: '300px' }} src={require('../icons/cop4.png')} />
                </div>
                <img style={{ height: '200px' }} src={require('../icons/cop4.jpeg')} />
            </div>
        </div>
    );
}
