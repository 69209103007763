import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import {
    Link
} from "react-router-dom";
import "../index.css";


export const Header = () => {
    const [isHeaderVisible, setIsHeaderVisible] = useState(false);
    return (
        <div>
            <FiMenu
                className="cellPhoneMenu"
                size={40}
                enableBackground="black"
                onClick={() => setIsHeaderVisible(!isHeaderVisible)}
            />
            <div
                className={`cellphoneFullScreen ${isHeaderVisible ? "Visible" : ""}`}
                onClick={() => setIsHeaderVisible(!isHeaderVisible)}
            ></div>
            <div className="mainDiv">
                <div className={`header ${isHeaderVisible ? "Visible" : ""}`}>
                    <div style={{ height: '60px' }} className='center'>
                        <img style={{ height: '40px' }} src={require('../icons/logo.jpg')} />
                    </div>
                    <div className="menuContainer">
                        <Link
                            className="menu"
                            onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                            to="/about"
                        >
                            Бидний тухай
                        </Link>
                        <Link
                            className="menu"
                            onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                            to="/news"
                        >
                            Мэдээ мэдээлэл
                        </Link>
                        <Link
                            className="menu"
                            onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                            to="/report"
                        >
                            Эмийн чанар, 
                            аюулгүй байдал
                        </Link>
                        <Link
                            className="menu"
                            onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                            to="/contact"
                        >
                            Холбоо барих
                        </Link>
                    </div>
                    <FiX
                        className="cellPhoneMenu"
                        size={40}
                        onClick={() => setIsHeaderVisible(!isHeaderVisible)}
                    />

                    {/* <div class="navBar">
                        <button className="footIcon footIconInHead">
                            <FiUser className="svgIcon" />
                        </button>
                        <button className="footIcon footIconInHead">
                            <FiGlobe className="svgIcon" />
                        </button>
                    </div> */}
                </div>
            </div>
        </div>
    )
}