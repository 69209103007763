import React from "react";
import { Route, Routes } from "react-router-dom";
import "./index.css";
import { AboutUs, Contact, News, Report, Post } from './pages/index';
import { Layout } from "./components/layout";

export default function App() {
  return (
    <Layout >
      <Routes>
        <Route path="/" element={<AboutUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/news" element={<News />} />
        <Route path="/report" element={<Report />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/news/:id" element={<Post />} />

      </Routes>
    </Layout>
  );
}
