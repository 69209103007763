import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { addDoc, collection } from 'firebase/firestore';
import React, { useEffect, useState } from "react";
import { Title } from "../components/title";
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const Section_3 = {
    symptoms: [
        { name: "Бөөлжих", value: "Throw up" },
        { name: "Толгой өвдөх", value: "Headache" },
        { name: "Цус алдалт", value: "Bleeding" },
        { name: "Нүүр уруул хэл хавагнах", value: "Swelling of the face, lips and tongue" },
        { name: "Дотор муухайрах", value: "Nausea" },
        { name: "Толгой эргэх, ухаан алдах", value: "Dizziness, fainting" },
        { name: "Амьсгал давчдах", value: "Shortness of breath" },
        { name: "Гар, хөл бадаарах", value: "Numbness in hands and feet" },
        { name: "Гүйлгэх", value: "Scroll" },
        { name: "Нойргүйдэх", value: "Insomnia" },
        { name: "Зүрх дэлсэх", value: "Heart palpitations" },
        { name: "Уналт, таталт", value: "Falls and seizures" },
        { name: "Хэвлийгээр өвдөх", value: "Abdominal pain" },
        { name: "Анафилакси", value: "Anaphylaxis" },
        { name: "Арьс, салст шарлах", value: "Yellowing of the skin and mucous membranes" },
        { name: "Загатнах", value: "Itching" },
        { name: "Арьсны тууралт", value: "Skin rash" },
        { name: "Шээсний гарц, өнгө өөрчлалт", value: "Urine output and discoloration" },
    ],
    case_of_side_effects: [
        { name: 'Эмийн хэрэглээг зогсоосон', value: 'Medication was discontinued' },
        { name: 'Эмийн тунг багасгасан', value: 'The dose of medicine was reduced' },
        { name: 'Эмийг сольсон', value: 'The medicine was changed' },
        { name: 'Антидот хэрэглэсэн', value: 'Antidote was used' }
    ],
    stopping_use_of_drugs_side_effects: [
        { name: "Шинж тэмдэг арилсан", value: 'Symptoms are gone' },
        { name: "Шинж тэмдэг үргэлжилж байгаа", value: 'Symptoms persist' },
        { name: "Шинж тэмдэг даамжирсан", value: 'Symptoms worsened' }
    ],
    end_of_side_effects: [
        { name: 'Эдгэрсэн', value: 'Healed' },
        { name: 'Үргэлжилж буй', value: 'Ongoing' },
        { name: 'Эмчилгээний хугацааг сунгасан', value: 'Treatment period extended' },
        { name: 'Эрчимт эмчилгээ хийсэн', value: 'Intensive treatment was performed' },
        { name: 'Хөгжлийн бэрхшээл', value: 'Disability' },
        { name: 'Нас барсан', value: 'Died' },
    ]
}

export const Report = () => {
    const navigate = useNavigate()
    const [send, setSend] = useState(false)
    const [active, setActive] = useState(true)
    const [section1, SetSection1] = useState({
        name: '',
        birth_date: '',
        age: '',
        sex: '',
        weight: '',
        height: '',
        phoneNumber:'',
        structural_features: '',
        prewDrug: ''
    })
    console.log(section1)
    const [section2, setSection2] = useState({
        inoadr: '',
        trade_name: '',
        dose_of_pack: '',
        expire_date: '',
        brand: '',
        region: '',
        serial_number: '',
        type_of_medicine: '',
        cost_of_drug_supply_date: '',
        date_of_discontinuation_of_medication: '',
        basic_diagnosis: '',
        indicators_for_drug_use: '',
        single_dose: '',
        daily_dose: ''
    })

    const [section3, setSection3] = useState({
        date_of_side_effect: '',
        hour_of_side_effect: '',
        type_of_side_effects: [],
        sign_of_side_effects: '',
        actions_taken_in_side_effect: [],
        changes_in_quit_drug_effects: '',
        end_of_side_effects: ''
    })

    const [section4, setSection4] = useState({
        medicine_used_with_drug: ''
    })

    const collectionRef = collection(db, 'reports')
    const SendReport = () => {
        addDoc(collectionRef, {
            sendDate: 'Mon Feb 20 2023 21:31:37 GMT+0800 (Ulaanbaatar Standard Time)',
            section1: section1,
            section2: section2,
            section3: section3,
            section4: section4,
        })
            .then(async () => {
                await toast.success('илгээсэн', { autoClose: 2000 })
                await setActive(true)
                await setTimeout(() => {
                    navigate('/')
                }, 2000);
            })
            .catch(() => {
                toast.error('тайлан илгээх боломжгүй', { autoClose: 2000 })
            })
    }
    const Check = (Obj) => {
        const result = Object.values(Obj).map((value) => {
            if (typeof value == String) {
                if (value == '') {
                    return false
                } else return true
            } else {
                if (value == []) {
                    return false
                } else return true
            }
        }).filter((item) => item == false)
        return result.includes(false)
    }


    useEffect(() => {
        const result = [Check(section1), Check(section2), Check(section3), Check(section4)]

        if (result.includes(true) !== true) {
            setActive(false)
        } else {
            setActive(true)
        }

    }, [section1, section2, section3, section4])

    return (
        <div>
            <ToastContainer />
            <div style={{ padding: "35px" }}>
                <Title>
                    Эмчлүүлэгчийн мэдээлэл
                </Title>
                <div className='wrapper' >
                    <TextField label="нэр" value={section1.name} onChange={(e) => SetSection1({...section1 ,name: e.target.value})} />
                    <FormControl className='report-input'>
                        <InputLabel id="demo-simple-select-standard-label">хүйс</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={section1.sex}
                            onChange={(e) => SetSection1({ ...section1, sex: e.target.value })}
                            label="sex"
                        >   
                            <MenuItem value={"male"}>эрэгтэй</MenuItem>
                            <MenuItem value={"female"}>эмэгтэй</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className='report-input'>
                        <InputLabel id="demo-simple-select-standard-label">Өвчтөний онцлог</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={section1.structural_features}
                            onChange={(e) => SetSection1({ ...section1, structural_features: e.target.value })}
                            label="structural features"
                        >
                            <MenuItem value={"Pregnant"}>Жирэмсэн</MenuItem>
                            <MenuItem value={"Breast"}>Хөхүүл</MenuItem>
                            <MenuItem value={"Others"}>Бусад</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField placeholder='yyyy/mm/dd' label="Төрсөн он, сар, өдөр:" value={section1.birth_date} onChange={(e) => SetSection1({ ...section1, birth_date: e.target.value })} />
                    <TextField placeholder='weight' label="Биеийн жин:" value={section1.weight} onChange={(e) => SetSection1({ ...section1, weight: e.target.value })} />
                    <TextField placeholder='age' label="Нас:" value={section1.age} onChange={(e) => SetSection1({ ...section1, age: e.target.value })} />
                    <TextField placeholder='height' label="Өндөр:" value={section1.height} onChange={(e) => SetSection1({ ...section1, height: e.target.value })} />
                    <TextField placeholder='height' label="Утасий дугаар:" value={section1.phoneNumber} onChange={(e) => SetSection1({ ...section1, phoneNumber: e.target.value })} />
                </div>
                <TextField sx={{ width: '100%', mt: '15px' }}
                    id="standard-basic"
                    placeholder='Previous drugs with side effects'
                    label="Урьд өмнө гаж нөлөө илэрч байсан эмийн нэр:"

                    value={section1.prewDrug}
                    onChange={(e) => SetSection1({ ...section1, prewDrug: e.target.value })}
                />
                <Title>
                    Эмийн мэдээлэл /Information on drug with side effects/
                </Title>
                <div className='wrapper' >
                    <TextField placeholder='International Nomenclature of Adverse Drug Reactions' label="Гаж нөлөө үзүүлсэн эмийн олон улсын нэршил" value={section2.inoadr} onChange={(e) => setSection2({ ...section2, inoadr: e.target.value })} />
                    <TextField placeholder='Trade name' label="Худалдааны нэршил" value={section2.trade_name} onChange={(e) => setSection2({ ...section2, trade_name: e.target.value })} />
                    <TextField placeholder='Dose of packaging' label="Савлалтын тун" value={section2.dose_of_pack} onChange={(e) => setSection2({ ...section2, dose_of_pack: e.target.value })} />
                    <TextField placeholder='yyyy/mm/dd' label="Хүчинтэй хугацаа" value={section2.expire_date} onChange={(e) => setSection2({ ...section2, expire_date: e.target.value })} />
                    <TextField placeholder='brand' label="Эм үйлдвэрлэгчийн нэр" value={section2.brand} onChange={(e) => setSection2({ ...section2, brand: e.target.value })} />
                    <TextField placeholder='Улс' label="Улс" value={section2.region} onChange={(e) => setSection2({ ...section2, region: e.target.value })} />
                    <TextField placeholder='serial number' label="Цувралын дугаар" value={section2.serial_number} onChange={(e) => setSection2({ ...section2, serial_number: e.target.value })} />
                    <FormControl className='report-input'>
                        <InputLabel id="demo-simple-select-standard-label">Эмийн хэлбэр</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={section2.type_of_medicine}
                            onChange={(e) => setSection2({ ...section2, type_of_medicine: e.target.value })}
                            label="structural features"
                        >
                            <MenuItem value={"Капсул"}>Капсул</MenuItem>
                            <MenuItem value={"Таблетууд"}>Шахмал</MenuItem>
                            <MenuItem value={"Судсаар хийх шингэн"}>Судсаар сэлбэх шингэн</MenuItem>
                            <MenuItem value={"Бусад"}>Бусад</MenuItem>
                            <MenuItem value={"Тарилга"}>Тарилга</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField placeholder='yyyy/mm/dd' label="Эм хэрэглэж эхэлсэн он сар өдөр" value={section2.cost_of_drug_supply_date} onChange={(e) => setSection2({ ...section2, cost_of_drug_supply_date: e.target.value })} />
                    <TextField placeholder='yyyy/mm/dd' label="Эм хэрэглэхийг зогсоосон он сар өдөр" value={section2.date_of_discontinuation_of_medication} onChange={(e) => setSection2({ ...section2, date_of_discontinuation_of_medication: e.target.value })} />
                    <TextField placeholder='Basic diagnosis' label="Үндсэн онош" value={section2.basic_diagnosis} onChange={(e) => setSection2({ ...section2, basic_diagnosis: e.target.value })} />
                    <TextField placeholder='Indications for drug use' label="Эм хэрэглэсэн заалт" value={section2.indicators_for_drug_use} onChange={(e) => setSection2({ ...section2, indicators_for_drug_use: e.target.value })} />
                    <TextField placeholder='Single dose' label="Нэг удаагийн хэрэглэсэн тун" value={section2.single_dose} onChange={(e) => setSection2({ ...section2, single_dose: e.target.value })} />
                    <TextField placeholder='Daily dose' label="Хоногт хэрэглэсэн тун" value={section2.daily_dose} onChange={(e) => setSection2({ ...section2, daily_dose: e.target.value })} />
                </div>
                <Title>
                    Гаж нөлөөний мэдээлэл /Side effects information/
                </Title>
                <div className='wrapper' >
                    <TextField placeholder='Date of improvement of side effects' label="Гаж нөлөө эхэлсэн он сар өдөр" value={section3.date_of_side_effect} onChange={(e) => setSection3({ ...section3, date_of_side_effect: e.target.value })} />
                    <TextField placeholder='Hours and minutes of onset of side effects' label="Гаж нөлөө эхэлсэн цаг минут" value={section3.hour_of_side_effect} onChange={(e) => setSection3({ ...section3, hour_of_side_effect: e.target.value })} />
                    <FormControl >
                        <Select
                            id="demo-multiple-chip"
                            multiple
                            displayEmpty
                            value={section3.type_of_side_effects}
                            onChange={(event) => {
                                const { target: { value }, } = event;
                                setSection3({ ...section3, type_of_side_effects: typeof value === 'string' ? value.split(',') : value });
                            }}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em>Илэрсэн гаж нөлөөний хэлбэр</em>;
                                }

                                return selected.join(', ');
                            }}
                            defaultValue=""
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {Section_3.symptoms.map((symptom) => (
                                <MenuItem
                                    key={symptom.name}
                                    value={symptom.name}
                                >
                                    {symptom.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField placeholder='Actions taken in case of side effects:' label="Илэрсэн гаж нөлөөний шинж тэмдгийг дэлгэрэнгүй бичнэ үү!" value={section3.sign_of_side_effects} onChange={(e) => setSection3({ ...section3, sign_of_side_effects: e.target.value })} />
                    <FormControl >
                        <Select
                            // variant='standart'
                            multiple
                            displayEmpty
                            value={section3.actions_taken_in_side_effect}
                            onChange={(e) => {
                                setSection3({ ...section3, actions_taken_in_side_effect: e.target.value });
                            }}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <em>Гаж нөлөө илэрсэн үед авсан арга хэмжээ:</em>;
                                }

                                return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="">
                                <em>Placeholder</em>
                            </MenuItem>
                            {Section_3.case_of_side_effects.map((symptom) => (
                                <MenuItem
                                    key={symptom.name}
                                    value={symptom.name}
                                >
                                    {symptom.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        <Select
                            variant='standart'
                            displayEmpty
                            value={section3.changes_in_quit_drug_effects}
                            onChange={(e) => {
                                setSection3({ ...section3, changes_in_quit_drug_effects: e.target.value });
                            }}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="">
                                <em>Гаж нөлөө илэрсэн эмийн хэрэглээг зогсооход гарсан өөрчлөлт:</em>
                            </MenuItem>
                            {Section_3.stopping_use_of_drugs_side_effects.map((symptom) => (
                                <MenuItem
                                    key={symptom.name}
                                    value={symptom.name}
                                >
                                    {symptom.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl >
                        <Select
                            variant='standart'
                            displayEmpty
                            value={section3.end_of_side_effects}
                            onChange={(e) => {
                                setSection3({ ...section3, end_of_side_effects: e.target.value });
                            }}
                            input={<OutlinedInput />}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem disabled value="">
                                <em>Гаж нөлөөний төгсгөл:</em>
                            </MenuItem>
                            {Section_3.end_of_side_effects.map((symptom) => (
                                <MenuItem
                                    key={symptom.name}
                                    value={symptom.name}
                                >
                                    {symptom.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <Title>
                    Хамт хэрэглэсэн эмийн мэдээлэл
                </Title>
                <TextField sx={{ width: '100%' }}
                    value={section4.medicine_used_with_drug}
                    onChange={(e) => setSection4({ ...section4, medicine_used_with_drug: e.target.value })}
                    id="standard-basic"
                    placeholder='Please specify trade name, international name, form, dosage, single dose, and duration of use'
                    label="Худалдааны нэр, олон улсын нэр, хэлбэр, тун, нэг удаагийн тун, хэрэглэсэн хугацааг нарийн бичнэ үү:"
                />
                <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Button disabled={active} variant="contained" onClick={SendReport}>Илгээх</Button>
                </div>
            </div>

        </div >
    )
}