import { collection } from "@firebase/firestore";
import {
    onSnapshot
} from 'firebase/firestore';
import React, { useState, useEffect } from "react";
import { db } from '../firebase';
import { useNavigate } from "react-router-dom";
const Card = ({ post }) => {
    const navigate = useNavigate()
    console.log(post)

    var elem = document.createElement('div');
    elem.style.display = 'none';
    document.body.appendChild(elem);
    elem.innerHTML = post.docsDesc;
    const image = elem.querySelector('img')?.src
    let htmlCollection = elem.querySelector('p').innerText
    console.log(htmlCollection)

    return (
        <div class="card-container" onClick={() => navigate(`/news/${post.id}`, {
            state: {
                post: post
            }
        })}>
            <div class="card">
                <div class="card__header">
                    <img src={image} alt="card__image" class="post-img card__image" width="300px" />
                </div>
                <div class="card__body">
                    <h4>{post.title}</h4>
                    <p></p>
                </div>
            </div>
        </div>
    )
}


export const News = () => {
    const [news, setNews] = useState()
    const collectionRef = collection(db, 'news')


    const getData = () => {
        onSnapshot(collectionRef, (data) => {
            setNews(data.docs.map((doc) => {
                return { ...doc.data(), id: doc.id }
            }))
        })
    }
    console.log(news)

    useEffect(() => {
        getData()
    }, [])

    return (
        <div style={{ padding: '20px' }}>
            <div class='news-wrapper'>
                {news ? news.map((post) => (<Card post={post} />)) : ''}
            </div>
        </div>
    )
}