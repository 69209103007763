import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "@firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyBNIl7C7EfL2IxNUUZbUERZCOnOjFvdi9U",
    authDomain: "ulziidun-hhk.firebaseapp.com",
    projectId: "ulziidun-hhk",
    storageBucket: "ulziidun-hhk.appspot.com",
    messagingSenderId: "933494475639",
    appId: "1:933494475639:web:aa3c9640211e813982ef72",
    measurementId: "G-6GD2BCB6ND"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const storage = getStorage(app);


export { app, db, storage }