import React from "react";
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';


const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoicmFkbmFhc3VyZW4iLCJhIjoiY2szOGU3ZTE2MDE3NjNjcWVkb2N2NHlndSJ9.I-rGRvV7oQQo1ryVpnW6lA'
});

export const Contact = (props) => {

    return (
        <div>
            <div className="text-text">
                <div>
                    <h2 className="">Манайхаар зочлоорой</h2>
                    <br />
                    <div className="">Баянгол дүүрэг, 1-р хороо,  Хатанбаатар Магсаржавын гудамж-36, 16052 </div>
                    <br />
                    T :  (+976) 7000-5354
                    <br />
                    M:  (+976) 8069-5353
                </div>
                <div>
                    <h2 className="">Нээлттэй цаг</h2>
                    <br />
                    <div className="">
                        Даваагаас Баасан гараг хүртэл | 09:00–17:00
                        <br />
                        <br />
                        Бямба/Ням гараг | Хаалттай
                    </div>
                </div>
            </div>
            <div className="mapDiv">
                {/* <div style={{ maxHeight: '300px' }}> */}
                    <img src={require('../icons/map.png')} alt="card__image" class="post-img" />
                {/* </div> */}
            </div>
        </div>
    );
}

